<template>
  <div>
    <BuyUnits />
  </div>
</template>

<script>
import BuyUnits from "@/views/payment/BuyUnits";

export default {
  components: { BuyUnits },
};
</script>
