<template>
  <div>
    <SenderID />
  </div>
</template>

<script>
import SenderID from "@/views/communication/SenderID";

export default {
  components: { SenderID },
};
</script>
